import React from 'react'

export const HomeScreen = () => {

    return (
        <div className="container home__page">
            <img className="home__image" src={ './assets/images/25-aniversario.jpg' } alt="Imagen del Orca Uno" title="Imagen del Orca Uno"></img>
        </div>
    )

}
